import React from "react";

const SectionPicker = ({sections}) => {
  return (
    <div className="flex flex-wrap px-5 justify-evenly">
      {sections.map((section, index) => (
        <div key={index} className={`w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 h-96 overflow-hidden p-5 relative`}>
          <a href={section.link} className="group overflow-hidden">
            <img src={section.image} alt={section.title}
                 className="w-full h-full object-cover rounded group-hover:scale-125 transition-all duration-300 ease-in-out"/>
            <p
              className="absolute bottom-10 left-10 right-10 p-2 text-center text-black group-hover:text-red-600 bg-white font-bold transition-all duration-300 ease-in-out transform -translate-y-1/2">
              {section.title}
            </p>
          </a>
        </div>
      ))}
    </div>
  );
};

export default SectionPicker;
