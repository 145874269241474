import React from 'react';
import {graphql} from 'gatsby';
import ImageGallery from "react-image-gallery";

import ImageCaptionSection from "../../components/imageCaptionSection";
import HelpMethodsSection from "../../components/helpMethodsSection";
import Layout from "../../components/layout";
import SectionPicker from "../../components/sectionPicker";


const ProjectTemplate = ({data, ...props}) => {
  const {frontmatter, html} = data.markdownRemark;
  const {title, image: imageSrc, projectGallery, subSections=[]} = frontmatter;

  const galleryImages = (projectGallery || []).map(({image, caption}) => ({
    original: image,
    thumbnail: image,
    description: caption,
  }));

  return (
    <Layout activeLink="/our-focus" pageTitle={title} pageDescription="" {...props} dynamicNavbar lightMenu>
      <ImageCaptionSection title="" subTitle={title} imageAlt={title} imageSrc={imageSrc} fullHeight useTextFadeIn/>
      <div dangerouslySetInnerHTML={{__html: html}}
           className="markdown-html p-10 text-justify"/>
      {!!subSections && (
        <div className="my-10">
          <SectionPicker sections={subSections}/>
        </div>
      )}
      {!!projectGallery?.length && (
        <div>
          <ImageGallery items={galleryImages} showThumbnails={false}/>
        </div>
      )}
      <HelpMethodsSection/>
    </Layout>
  );
};

export const query = graphql`
  query ProjectTemplateQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        image
        subSections {
          image
          title
          link
        }
        projectGallery {
          caption
          image
        }
      }
      html
    }
  }
`;

export default ProjectTemplate;
